__webpack_public_path__ = crb_app.public_path;

import NavScrollSpy from 'nav-scroll-spy';
import 'jquery-mousewheel';
import 'malihu-custom-scrollbar-plugin';
import 'selectric';
import Swiper from 'swiper/dist/js/swiper';
import Plyr from 'plyr/dist/plyr.polyfilled.min.js';

if ( $('#locations').length ) {
	let locations = () => import( './vue/locations' );

	locations();
}

let hash = window.location.hash;
const $win = $(window);
const $doc = $(document);
const $body = $('body');

let players = [];

$win.on('load', function() {
    if ( hash.length > 0 ) {
    	$('body, html').scrollTop(0, 0);

        setTimeout(function() {
            scrollByID( hash );
        }, 200);
    }
});

$win.on('scroll', function() {
	$body.toggleClass('scrolled', $win.scrollTop() > 100);
});

function scrollByID( hash ) {
    if ( ! hash ) {
        return false;
    };

    let element = $( hash );

    if ( element.length > 0 ) {
        $('html').animate({
           scrollTop: element.offset().top
       }, 1000 );
    };
}

// Mobile Navigation
if ( !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
	$body.addClass('no-touch');
} else {
	$body.addClass('is-touch');
};

$('form.form-select.redirect select').on('change', function() {
	window.location = $(this).val();
});

//  Nav mobile
$('.nav-trigger').on('click', function(e){
	    e.preventDefault();

    if( !$(this).hasClass('open') && !$body.hasClass('show-nav') ){
	    $(this).addClass('open');
	    $body.addClass('show-nav');
    } else {
	    $(this).removeClass('open');
	    $body.removeClass('show-nav');
    }
});

// Mobile Nav
 $('.nav a').on('click', function(e){
	var $this = $(this);
	var $parent = $this.parent();
	var $hasSubMenu = $this.next('ul');

	if($win.width() < 1024 && $hasSubMenu.length ) {
		if(!$parent.hasClass('hover')) {
			e.preventDefault();

			$parent.addClass('hover').siblings().removeClass('hover');
		}
	}

	if($body.hasClass('is-touch') && $hasSubMenu.length ) {
		if(!$parent.hasClass('hover')) {
			e.preventDefault();

			$parent.addClass('hover').siblings().removeClass('hover');
		}
	}
});

$body.on('click touchstart', function(event){
	var $target = $(event.target);

	if(!$target.parents('li').length){
		$('li').find('ul').parent().removeClass('hover');
	}

	if($body.hasClass('is-touch') && $win.width() <= 768){
		if(!$target.parents('.nav li').length ){
			$('.nav li').find('ul').removeClass('hover');
		}
	}
});

// Nav Scroll
function navScroll() {
	let scrollWrapper = $('.nav-scroll-wrapper');

	if ( scrollWrapper.length ) {
		if( $('.vc_ie-flexbox-fixer').length ){
			var $section = $('.vc_ie-flexbox-fixer');

			$section.each(function() {
				let $this = $(this);
				let idIE = $this.find('.vc_row').attr('id');
				let newID = $this.attr('id', `ie-${idIE}`);
				let getNewID = $this.attr('id');

				if ( idIE ) {
					scrollWrapper.find('ul').append('<li><a href="#' + getNewID + '"></a></li>');
				} else {
					$('.nav-scroll').addClass('not-visible');
				}
			});
		} else {
			$section = $('.vc_row');

			$section.each(function() {
				let $this = $(this);
				let id = $this.attr('id');

				if ( id ) {
					scrollWrapper.find('ul').append('<li><a href="#' + id + '"></a></li>');
				} else {
					$('.nav-scroll').addClass('not-visible');
				}
			});
		}
	}

	function animateViewportScroll(to, time, callback) {
		$('html, body').animate({
			scrollTop: to
		}, time, 'linear', callback);
	}

	$('.nav-scroll a').on('click', function(e) {
		e.preventDefault();

		let target = $(this.hash).offset().top + 1;

		animateViewportScroll(target, 600);
	});

	$('.nav-title a, .btn-scroll').on('click', function(e){
		e.preventDefault();

		if( $(this).hasClass('bottom-position') ){
			$('body, html').animate({
				scrollTop: 0
			}, 1200);
		} else {
			let target = $('.in-viewport').next().offset().top + 1;

			if( $('.vc_row_overlap.in-viewport + .vc_row_overlap').length ){
				target = $('.in-viewport').next().offset().top - 40;
			}

			animateViewportScroll(target, 600);
		}
	});
}

function scrollToNextSection() {
	const $nextSection = $('.in-viewport').next();
	let sectionTitle = $nextSection.find('.wpb_text_column .wpb_wrapper h3').text();
	let sectionTitle2 = $nextSection.find('.wpb_text_column .wpb_wrapper h2').text();
	let sectionTitle3 = $nextSection.find('.wpb_text_column .wpb_wrapper h1').text();
	const $footerPrev = $('.footer').prev();
	let footerHeight = $('.footer').outerHeight();
	const hasArticle = $nextSection.find('.article-1of2');
	const hasArticleSingle = $nextSection.find('.article--single');
	const hasSlider = $nextSection.find('.slider-wine');
	const hasSliderMain = $nextSection.find('.slider-main');
	const hasSectionNews = $nextSection.find('.section-news');

	if( hasArticle.length ){
		sectionTitle = $nextSection.find('.article-1of2.visible h3').text();

		$('.nav-title a').text(sectionTitle);
	} else if( hasSlider.length ) {
		sectionTitle = $nextSection.find('.section-slider.visible h3').text();

		$('.nav-title a').text(sectionTitle);
	} else if( hasSliderMain.length ){
		sectionTitle = $nextSection.find('.section-slider-main h3').text();

		$('.nav-title a').text(sectionTitle);
	} else if( hasSectionNews.length ){
		sectionTitle = $nextSection.find('.section-news h3').text();

		$('.nav-title a').text(sectionTitle);
	} else if( hasArticleSingle.length ){
		sectionTitle = $nextSection.find('.article--single h2').text();

		$('.nav-title a').text(sectionTitle);
	} else {
		$('.nav-title a').text(sectionTitle || sectionTitle2 || sectionTitle3);
	}

	if( $footerPrev.hasClass('in-viewport') || $('.footer').hasClass('in-viewport') ){
		$('.nav-title a').text( $('.nav-title').data('default') );

		$('.nav-title a, .btn-scroll').addClass('bottom-position');
	} else {
		$('.nav-title a, .btn-scroll').removeClass('bottom-position');
	}

	if( $('.footer').hasClass('in-viewport') ){
		$('.nav-scroll-wrapper').css('bottom', footerHeight);
	} else {
		$('.nav-scroll-wrapper').css('bottom', 0);
	}
}

// Is In Viewport
function isInViewport(el, offset){
	if (!el.length) {
		return false;
	}

	offset = offset || 0;

	var elementTop = el.offset().top + 100;
	var elementBottom = elementTop + el.height();
	var screenTop = $win.scrollTop() + offset;
	var screenBottom = screenTop + $win.height() + offset;

	if( $('.in-viewport + .footer').length ) {
		var elementTop = el.offset().top + 100;
	}

	if( $('.in-viewport + .footer').length && $('.section-locations').length ) {
		var elementTop = el.offset().top + 100;
	}

	if ( (screenBottom > elementTop) && (screenTop < elementBottom) ) {
		return true;
	} else {
		return false;
	}
}

$win.on('load', function(){
	$body.addClass('loaded');

	setTimeout(function() {
		$body.addClass('animation-over');
	}, 3000);

	$('.vc_ie-flexbox-fixer + .vc_row-full-width.vc_clearfix').remove();
	$('.vc_row + .vc_row-full-width.vc_clearfix').remove();

	navScroll();

	let spy = new NavScrollSpy({
	 	selector: '.nav-scroll a',
	 	offset: $win.height() / 2,
	});

	spy.init();

	$('.vc_row_wines .first-active li:first-child').addClass('active');

	if ($win.width() > 767) {
		$('.vc_row_wines li, .intro-wines li').on('mouseover', function(event){
			const $this = $(this);
			const index = $this.index();
			const imageIndex = $('.intro-wines .intro__image-inner').eq(index);

			if( !$this.hasClass('active') ){
				$this.addClass('active').siblings().removeClass('active');
				imageIndex.addClass('active').siblings().removeClass('active');
			}
		});
	} else {
		$('.intro-wines li a').on('click', function(event){
			event.preventDefault();

			const $this = $(this);
			const $link = $this.attr('href');
			const $parent = $this.parent();
			const index = $parent.index();
			const imageIndex = $('.intro-wines .intro__image-inner').eq(index);

			if( !$parent.hasClass('active') ){
				$parent.addClass('active').siblings().removeClass('active');
				imageIndex.addClass('active').siblings().removeClass('active');
			}

			setTimeout(function(){
				window.location = $link;
			}, 2000);
		});
	}
});

$win.on('load scroll', function() {
	animateItems( $win.scrollTop() );

	$('.vc_row, footer').each(function(){
		if(isInViewport($(this))){
			if( $('.vc_ie-flexbox-fixer').length ){
				$(this).parent().addClass('in-viewport').siblings().removeClass('in-viewport');

				$(this).addClass('animation');

				if( $('.vc_ie-flexbox-fixer.in-viewport + footer').length ) {
					$('footer').addClass('in-viewport');
				}
			} else {
				$(this).addClass('in-viewport').siblings().removeClass('in-viewport');

				$(this).addClass('animation');
			}
		} else {
			$(this).removeClass('in-viewport');
		}
	});

	scrollToNextSection();
});

// Custom scrollbar
if( $win.width() > 1023 ){
	$('.vc_row_scroll .wpb_scroll, .list-locations').mCustomScrollbar({
		mouseWheel: {
			enable: true,
			scrollAmount: 500
		},
		callbacks:{
			onTotalScroll: function(){
				$(this).parents('.vc_row_scroll').addClass('scroll-end');
				$(this).parents('.section-locations').addClass('scroll-end');
			},
			onScroll: function(){
				if( $(this).parents('.vc_row_scroll').hasClass('scroll-end') ){
					$(this).parents('.vc_row_scroll').removeClass('scroll-end');
				}

				if( $(this).parents('.section-locations').hasClass('scroll-end') ){
					$(this).parents('.section-locations').removeClass('scroll-end');
				}
			}
		}
	});
}

// Google Map
function initializeGoogleMaps() {
	$('.map:not(.map-vue) .map__inner').each( (i, element) => {
		const markers = [];
		const infowindows = [];
		const $element = $(element);
		const center = new google.maps.LatLng( $element.data('lat'), $element.data('lng') );

		const styles = [{"featureType": "water", "elementType": "geometry", "stylers": [{"color": "#e9e9e9"}, {"lightness": 17 } ] }, {"featureType": "landscape", "elementType": "geometry", "stylers": [{"color": "#f5f5f5"}, {"lightness": 20 } ] }, {"featureType": "road.highway", "elementType": "geometry.fill", "stylers": [{"color": "#ffffff"}, {"lightness": 17 } ] }, {"featureType": "road.highway", "elementType": "geometry.stroke", "stylers": [{"color": "#ffffff"}, {"lightness": 29 }, {"weight": 0.2 } ] }, {"featureType": "road.arterial", "elementType": "geometry", "stylers": [{"color": "#ffffff"}, {"lightness": 18 } ] }, {"featureType": "road.local", "elementType": "geometry", "stylers": [{"color": "#ffffff"}, {"lightness": 16 } ] }, {"featureType": "poi", "elementType": "geometry", "stylers": [{"color": "#f5f5f5"}, {"lightness": 21 } ] }, {"featureType": "poi.park", "elementType": "geometry", "stylers": [{"color": "#dedede"}, {"lightness": 21 } ] }, {"elementType": "labels.text.stroke", "stylers": [{"visibility": "on"}, {"color": "#ffffff"}, {"lightness": 16 } ] }, {"elementType": "labels.text.fill", "stylers": [{"saturation": 36 }, {"color": "#333333"}, {"lightness": 40 } ] }, {"elementType": "labels.icon", "stylers": [{"visibility": "off"} ] }, {"featureType": "transit", "elementType": "geometry", "stylers": [{"color": "#f2f2f2"}, {"lightness": 19 } ] }, {"featureType": "administrative", "elementType": "geometry.fill", "stylers": [{"color": "#fefefe"}, {"lightness": 20 } ] }, {"featureType": "administrative", "elementType": "geometry.stroke", "stylers": [{"color": "#fefefe"}, {"lightness": 17 }, {"weight": 1.2 } ] } ]

		const mapOptions = {
			zoom: 16,
			maxZoom: 16,
			center,
			// disableDefaultUI: true,
			backgroundColor: '#ffffff',
			panControl: false,
			scrollwheel: false,
			styles,
			zoomControl: true,
	      	zoomControlOptions: {
	        	position: google.maps.ControlPosition.LEFT_BOTTOM
	      	},
	      	streetViewControl: true,
  		    streetViewControlOptions: {
      	        position: google.maps.ControlPosition.LEFT_TOP
	      	},
	      	fullscreenControl: true,
	      	fullscreenControlOptions: {
	      		position: google.maps.ControlPosition.LEFT_CENTER
	      	}
		};

		let icon = new google.maps.MarkerImage($element.data('marker'), null, null, null, new google.maps.Size(35, 41));
		let newIcon = new google.maps.MarkerImage($element.data('marker'), null, null, null, new google.maps.Size(35, 41));

		const bounds = new google.maps.LatLngBounds();

		const map = new google.maps.Map(document.getElementById( $element.attr('id') ),mapOptions);

		$('.list-locations li').each( (i, element) => {
			const $element = $(element);
			const lat = $element.data('lat');
			const lng = $element.data('lng');
			const info = $element.html();

			// if (!lat && !lng) {
			// 	return;
			// }

			const position = new google.maps.LatLng( parseFloat(lat), parseFloat(lng));

			map.fitBounds(bounds);
			bounds.extend(position);

			const infowindow = new google.maps.InfoWindow({
				content: info,
				maxWidth: 320,
				pixelOffset: new google.maps.Size(160,180)
			});

			let marker = new google.maps.Marker({
				map,
				position,
				icon,
			});

			markers[i] = marker;
			infowindows[i] = infowindow;

			if (info) {
				if ( $('.map--full').length) {
		        	google.maps.event.addListener(marker, 'mouseover', () => {
			        	for (let j = 0; j < infowindows.length; j++) {
							infowindows[j].close();
			          		markers[j].setIcon(icon);
						}

		            	infowindows[i].open(map, markers[i]);

			          	markers[i].setIcon(newIcon);
					});
					if( $body.hasClass('is-touch') ){
			        	google.maps.event.addListener(marker, 'click', () => {
				        	for (let j = 0; j < infowindows.length; j++) {
								infowindows[j].close();
				          		markers[j].setIcon(icon);
							}

			            	infowindows[i].open(map, markers[i]);

				          	markers[i].setIcon(newIcon);
						});
					}
				} else {
		        	google.maps.event.addListener(marker, 'click', () => {
			        	for (let j = 0; j < infowindows.length; j++) {
							infowindows[j].close();
			          		markers[j].setIcon(icon);
						}

		            	infowindows[i].open(map, markers[i]);

			          	markers[i].setIcon(newIcon);
					});
				}
			}
		});
	});
}

if ( $('.map').length > 0 ) {
	google.maps.event.addDomListener(window, 'load', initializeGoogleMaps);
}

// Custom Select Option
$('.form-select select').selectric({
	disableOnMobile: false,
	nativeOnMobile: false
});

// Wine page select
let sliderWine = new Swiper('.section-slider.visible .slider-wine .swiper-container', {
	loop: true,
	speed: 1000,
	spaceBetween: 30,
	navigation: {
    	nextEl: '.swiper-button-next',
    	prevEl: '.swiper-button-prev',
	},
});

$('.section--wines-info select').on('change', function(){
	let selectText = $(this).val();

	let $new = $( '*[data-vintage=' + selectText + ']' );

	$new.addClass('visible').siblings().removeClass('visible');

	if ($new.hasClass('hidden') && $new.attr('data-parent')) {
		$($new.attr('data-parent')).addClass('hidden');
	} else {
		$($new.attr('data-parent')).removeClass('hidden');
	}

	$new.find( 'select' ).val( selectText ).selectric('refresh');

	sliderWine.destroy();

	sliderWine = new Swiper('.section-slider.visible .slider-wine .swiper-container', {
		loop: true,
		navigation: {
	    	nextEl: '.swiper-button-next',
	    	prevEl: '.swiper-button-prev',
		},
	});
});

//Scroll To Section
$('.btn--scroll').on('click', function(e) {
	e.preventDefault();

	$('body, html').animate({
		scrollTop: $($(this).attr('href')).offset().top
	}, 1000);
});

if( $('.slider-main').length ){
	$('.slider-main .swiper-container').each(function(){
		const sliderMain = new Swiper(this, {
			speed: 1000,
			loop: true,
			loopAdditionalSlides: 2,
			// autoHeight: true,
			navigation: {
		    	prevEl: '.swiper-button-prev',
		    	nextEl: '.swiper-button-next',
			},
			// on: {
			// 	init: function () {
			// 		videos();
			// 	}
			// }
		});

		sliderMain.on('slideChange', function () {
			let $prevSlide = $($('.slider-main .slider__slide')[this.previousIndex]);

			if($prevSlide.find('.video').length) {
				let playerIndex = $prevSlide.find('.video').index('.video');

				pauseVideo(playerIndex);
			}
		});

	});

	videos();
}

if( $('.slider-main_section-reverse').length ){
	$('.slider-main_section-reverse .swiper-container').each(function(){
		const sliderMainReverse = new Swiper(this, {
			speed: 1000,
			loop: true,
			loopAdditionalSlides: 2,
			autoHeight: false,
			navigation: {
		    	prevEl: '.swiper-button-prev',
		    	nextEl: '.swiper-button-next',
			},
		});
	});
}

if( $('.slider-main_second').length ){
	$('.slider-main_second .swiper-container').each(function(){
		const $this = $(this);

		const sliderMainSecond = new Swiper(this, {
			speed: 1000,
			loop: true,
			loopAdditionalSlides: 2,
			autoHeight: false,
			navigation: {
		    	prevEl: $this.find('.swiper-button-prev'),
		    	nextEl: $this.find('.swiper-button-next'),
			},
		});
	});
}

/**
 * Init Plyr for Videos
 */
function videos() {
	players = Array.from(document.querySelectorAll('.js-player')).map((elem, index) => {
		const $videoParent = $(elem).parent('.video').parent();

		const player = new Plyr(elem, {
			playsinline: true,
			clickToPlay: true,
			hideControls: false,
			fullscreen: {
				enabled: true,
				iosNative: true,
			},
		});

		player.poster = window.location.origin.includes('2create.studio') || window.location.origin.includes('localhost') ? `${window.location.origin+window.location.pathname}/wp-content/themes/chateau-trottevieille/resources/images/video-thumbnail.jpg` : `${window.location.origin}/wp-content/themes/chateau-trottevieille/resources/images/video-thumbnail.jpg`;

		player.on('playing', event => {
			$videoParent.parent().addClass('play-now');
			player.volume = 0.5;
		});

		player.on('pause', event => {
			$videoParent.parent().removeClass('play-now');
		});

		return {index, player}
	});
};


/**
 * Play function for Plyr Videos
 */
function playVideo(id){
	const video = players.find(elem => elem.index === id);

	const isLoaded = $(`#${video.id}`).is('.video-loaded');

	if (isLoaded) {
		video.player.play();
	} else {
		video.player.on('ready', event => {
			video.player.play();
		});
	}
}

/**
 * Pause function for Plyr Videos
 */
function pauseVideo(id) {
	const video = players.find(elem => elem.index === id);

	video.player.pause();
}

// Image to Background
$('.vc_image_to_background .isotope-item').each(function(){
	var imgSrc = $(this).find('img').attr('src');

	$(this).css('background-image', 'url('+ imgSrc +')');
});

// Animations for Blog and Post
function animateItems(topPosition) {
	const winHeight = $win.height();
	topPosition = topPosition;

	$('.articles .article, .article--single .article__head > *, .article--single .article__content > *').each((index, el) => {
		const elementTop = $(el).offset().top - winHeight;
		if ( topPosition < elementTop ) {
			return;
		}

		setTimeout(() => {
			$(el).addClass('fade-in-up');
		}, 100 + index * 200);
	});
}
